$(function () {
  $('#data-table').DataTable({
    "paging": true,
    "lengthChange": false,
    "searching": false,
    "ordering": true,
    "info": true,
    "autoWidth": false,
    "responsive": true,
    'aoColumnDefs': [{
      'bSortable': false,
      'aTargets': ['nosort']
    }]
  });

  /*$("#data-table-exportable").DataTable({
      "responsive": true, "lengthChange": false, "autoWidth": false,
      "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
    }).buttons().container().appendTo('#data-table_wrapper .col-md-6:eq(0)');*/

  $('[data-toggle="tooltip"]').tooltip()
  $('#dataTable').DataTable({})
});
